import React, {useEffect, useState} from "react";
import {Table} from "../../components/table/table";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import AlertConfirm from "../../components/modals/Confirm";
import SVGraphics from "../../assets/SVGraphics";
import {SlideInput} from "../../components/input/SlideInput";
import {SelectInput} from "../../components/input/SelectInput";
import {object, string} from "yup";
import utils from "../../utils/utils";
import {authenticationService} from "../../services/AuthinticationService";

const SNManagementComp = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [entries, setEntries] = useState([{}]);
    const [hoverEdit, setHoveredEdit] = useState(-1);
    const [hoverDelete, setHoveredDelete] = useState(-1);
    const [rowInEdit, setRowInEdit] = useState(-1);
    const [entry, setEntry] = useState({});
    const [allCompanies, setAllCompanies] = useState([]);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [sitesOptions, setSitesOptions] = useState({});


    const validateSchema = object().shape({
        External_Serial_Number: string().required('This field is a required field')
            .matches(/^[0-9]+$/, 'Field must contain only digits')
            .min(10, 'Minimum length must be at least 10 characters')
            .max(50, 'Maximum length can be at most 50 characters'),
        Internal_Serial_Number: string().required('This field is a required field')
            .matches(/^[0-9]+$/, 'Field must contain only digits')
            .min(1, 'Minimum length must be at least 1 characters')
            .max(10, 'Maximum length can be at most 10 characters'),
        FW_Version: string().required('This field is a required field')
            .matches(/^[0-9]+$/, 'Field must contain only digits')
            .min(4, 'Minimum length must be at least 4 characters')
            .max(10, 'Maximum length can be at most 10 characters'),
        Company_ID: string().required('This field is a required field'),
        Site_ID: string().required('This field is a required field'),
    })


    useEffect(() => {
        let companiesArr = []
        getAllDevices({}).then(p => {
            getAllCompanies().then(r => {
                let data = r.data
                data.map(company => {
                    companiesArr.push({value: company.Company_ID, label: company.Company_name})
                })
                setAllCompanies(data)
                setCompaniesOptions(companiesArr)
                setData(p)
            })
        })

    }, [isLoading])

    const getAllCompanies = async () => {
        return await props.restApi.getAllCompanies({})
    }

    const getAllDevices = async (filtersObj) => {
        return await props.restApi.getAllDevices(filtersObj)
    }

    const setData = (p) => {
        setEntries([...p.data])
        setFilteredData([...p.data])
        setIsLoading(false)
    }


    const handleHoverEdit = (index) => {
        setHoveredEdit(index);
    };
    const handleHoverDelete = (index) => {
        setHoveredDelete(index);
    };

    const handleUnhoverEdit = () => {
        setHoveredEdit(-1);
    };
    const handleUnhoverDelete = () => {
        setHoveredDelete(-1);
    };

    const handleEditRow = (row) => {
        handleSitesOptions(row.original)
        setEntry({...row.original})
        setRowInEdit(row.index)
    }

    const handleDeleteRow = async (row) => {

        console.log("row.original", row.original['Device_index'])
        console.log("row.index", row.index)
        let confirm = await AlertConfirm({
            proceedLabel: 'Delete',
            cancelLabel: 'Cancel',
            options: {hasSVG: true, svgName: "alert-triangle"},

        }, "The action can't be undone", `Are you sure you want to delete?`)
        if (confirm) {
            setIsLoading(true)
            await props.restApi.deleteDevice({Device_index: row.original['Device_index']})
            setIsLoading(false)
        }
    }
    const handleEditRowCancel = () => {
        setSitesOptions([])
        setEntry({})
        setRowInEdit(-1)
    }
    const handleSitesOptions = (entry) => {
        let sitesArr = []
        let findCompany = allCompanies.findIndex(item => item['Company_ID'] === entry['Company_ID'])
        if (findCompany >= 0) {
            allCompanies[findCompany]['sites'].map(site => {
                sitesArr.push({value: site['Site_ID'], label: site.Site_name})
            })
        }
        setSitesOptions(sitesArr)
    }

    const validateForm = async (currentEntry) => {
        let formErrors = {}
        await validateSchema.validate(currentEntry, {abortEarly: false})
            .then(function (value) {
            }).catch(function (err) {
                err.inner.forEach(error => {
                    formErrors[error.path] = error.errors
                    console.log(error)
                })
            });
        return formErrors
    }

    const handleEditRowSave = async () => {
        let currentEntry = {...entry}
        let validateEntry = await validateForm(currentEntry)

        let keys = Object.keys(validateEntry)
        if (keys.length > 0) {
            let messages = ""
            keys.map(item => messages = "\n" + item + ": " + validateEntry[item])
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},
            }, messages, `Cant edit row`)
        } else {
            let response = await props.restApi.editDeviceSerialNumber(currentEntry)
            if (response && response.data) {
                setIsLoading(true)

                setTimeout(() => {
                    setIsLoading(false)
                    setSitesOptions([])
                    setEntry({})
                    setRowInEdit(-1)

                }, 100);


            } else {
                await AlertConfirm({
                    proceedLabel: 'Ok',
                    cancelLabel: 'none',
                    options: {hasSVG: true, svgName: "alert-triangle"},
                }, "Please check External Serial Number and Internal Serial Number", `Unique validation error`)
            }
        }


    }

    const onRowChange = async (name, value) => {
        let obj = {...entry}
        if (value === "") {
            value = null
        }
        if (name === "Enabled") {
            value = !obj['Enabled']
        }
        obj[name] = value
        if (name === "Company_ID") {
            obj["Site_ID"] = ""
            handleSitesOptions(obj)
        }

        setEntry({...obj})
    }

    const editCellsArr = [<input
        key={'Input_External_Serial_Number'}
        type={"text"}
        className={'w-[15rem] px-[1.5rem] py-[0.75rem] h-[3rem] border rounded-lg border-light-grey bg-white'}
        value={entry['External_Serial_Number'] ? entry['External_Serial_Number'] : ''}
        onChange={e => onRowChange("External_Serial_Number", e.target.value)}
    />,
        <input
            key={'Input_Internal_Serial_Number'}
            className={'w-[13rem] px-[1.5rem] py-[0.75rem] h-[3rem] border rounded-lg border-light-grey bg-white'}
            value={entry['Internal_Serial_Number'] ? entry['Internal_Serial_Number'] : ''}
            onChange={(event) => onRowChange("Internal_Serial_Number", event.target.value)}
        />,
        <SelectInput
            key={"Company_Select"}
            containerClassName={"max-w-[23rem] h-[3rem]"}
            inputClassName={"w-[15rem] min-h-[3rem] max-w-[23rem] rounded-[0.5rem] bg-[#FEFEFE] "}
            maxHeight={"3rem"}
            onChange={(event) => onRowChange("Company_ID", event.value)}
            data={companiesOptions}
            value={entry['Company_ID']}
            placeholder={'Select'}
        />, <SelectInput
            key={"Site_Select"}
            containerClassName={"max-w-[23rem] h-[3rem]"}
            inputClassName={"w-[15rem] min-h-[3rem] max-w-[23rem] rounded-[0.5rem] bg-[#FEFEFE] "}
            maxHeight={"3rem"}
            onChange={(event) => onRowChange("Site_ID", event.value)}
            data={sitesOptions}
            value={entry['Site_ID']}
            placeholder={'Select'}
        />, <input
            key={'FW_Version_Input'}
            className={'w-[8rem] px-[1.5rem] py-[0.75rem] h-[3rem] border rounded-lg border-light-grey bg-white'}
            value={entry.FW_Version ? entry.FW_Version : ''}
            onChange={(event) => onRowChange("FW_Version", event.target.value)}
        />,
        <div className={"cursor-pointer"}>
            {entry['Status'] ? "Unlock" : "Lock"}
        </div>,
        <SlideInput
            name={"Enable_slide"}
            onChange={(e) => onRowChange("Enabled", e.target.value)}
            value={entry['Enabled']}
            step={1}
            marginTop={'mt-0'}
            min={0}
            max={1}
        />
        ,
        <div className={'flex'}>
            <SVGraphics
                fill={'none'}
                className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                onClick={() => handleEditRowSave()}
                svgname={'saveRow'}/>
            <SVGraphics
                fill={'none'}
                className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                onClick={() => handleEditRowCancel()}
                svgname={'cancelRow'}/>
        </div>]


    const columns = [
        {
            Header: 'External Serial Number',
            accessor: 'External_Serial_Number',
            headerClassName: 'w-[17.25rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}>
                    {cell.row.original['External_Serial_Number']}
                </div>)
            }
        },
        {
            Header: 'Internal Serial Number',
            accessor: 'Internal_Serial_Number',
            headerClassName: 'w-[15.5rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}>
                    {cell.row.original?.Internal_Serial_Number}
                </div>)
            }
        },
        {
            Header: 'Company',
            accessor: 'Company',
            headerClassName: 'w-[17rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}>
                    {cell.row.original['company']?.Company_name}
                </div>)
            }
        },
        {
            Header: 'Site',
            accessor: 'Site_ID',
            headerClassName: 'w-[17rem]',
            Cell: (cell) => {

                return (<div className={"cursor-pointer"}>
                    {cell.row.original?.site?.Site_name}
                </div>)
            }
        },
        {
            Header: 'FW Version',
            accessor: 'FW_Version',
            headerClassName: 'w-[10rem]',
            Cell: (cell) => {

                return (<div className={"cursor-pointer"}
                >
                    {cell.row.original?.FW_Version}
                </div>)

            }
        },
        {
            Header: 'Status',
            accessor: 'Status',
            headerClassName: 'w-[5.313rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}>
                    {cell.row.original && cell.row.original['Status'] ? "Unlock" : "Lock"}
                </div>)
            }
        },
        {
            Header: 'Enable',
            accessor: 'Enabled',
            sortType: (rowA, rowB, columnId) => {
                const a = rowA.values[columnId] ? 1 : 0;
                const b = rowB.values[columnId] ? 1 : 0;
                return a - b;
              },
            Cell: (cell) => {
                let value = cell.row.original && cell.row.original['Enabled']
                if (rowInEdit === cell.row.index) {
                    value = entry['Enabled']
                }
                return (
                    <SlideInput
                        name={"Enable" + cell.row.index}
                        disabled={true}
                        onChange={(e) => onRowChange("Enabled", e.target.value)}
                        value={value}
                        step={1}
                        marginTop={'mt-0'}
                        min={0}
                        max={1}
                    />
                )
            }
        },
        {
            Header: '',
            accessor: 'Edit',
            Cell: (cell) => {

                return (
                    <SVGraphics
                        onMouseEnter={() => handleHoverEdit(cell.row.index)}
                        onMouseLeave={handleUnhoverEdit}
                        className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                        onClick={() => handleEditRow(cell.row)}
                        svgname={hoverEdit === cell.row.index ? 'editHovered' : 'edit'}/>)
            }

        },
        {
            Header: '',
            accessor: 'delete',
            Cell: (cell) => {
                let currentUser = authenticationService.isLoggedin() ? authenticationService.currentUserValue.role : ""
                if (currentUser === "SuperAdmin" || currentUser === "Admin") {
                    return (
                        <SVGraphics
                            onMouseEnter={() => handleHoverDelete(cell.row.index)}
                            onMouseLeave={handleUnhoverDelete}
                            className={"w-[1.5rem] h-[1.5rem] cursor-pointer group"}
                            onClick={() => handleDeleteRow(cell.row)}
                            svgname={hoverDelete === cell.row.index ? 'deleteIconHovered' : 'deleteIcon'}/>
                    )
                }
            }
        },


    ]

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        let filteredData = entries.filter(user =>
            String(user['External_Serial_Number']).toLowerCase().indexOf(filterValue) > -1 ||
            String(user['Internal_Serial_Number']).toLowerCase().indexOf(filterValue) > -1
        )
        setFilteredData(filteredData)
    }


    const uploadSNFile = async (file) => {
        const formData = new FormData();
        formData.append('FileToExport', file[0]);
        let response = await props.restApi.ExportFile(formData)
        if (response && response.status === 200) {
            setIsLoading(true)
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "greenOk"},

            }, "Changes have been successfully saved.", ``)
            setIsLoading(false)

        } else {
            await AlertConfirm({
                proceedLabel: 'Ok',
                cancelLabel: 'none',
                options: {hasSVG: true, svgName: "alert-triangle"},

            }, "Could not upload file", ``)
        }

    }


    const onDownloadClick = () => {
        let headers = ["Date", "External Serial Number", "Internal Serial Number", "Company", "Site", "FW Version", "Status", "Enable"]
        let dataToAdd = filteredData.map(item => {
            let Lock = item['Status'] ? "Lock" : "Unlock"
            let status = item['Enabled'] ? "Active" : "Disable"
            return [
                utils.getFormattedDate(item['createdAt']),
               Number(item['External_Serial_Number']) ,
                Number(item['Internal_Serial_Number']),
                item['company']['Company_name'],
                item['site']['Site_name'],
                Number(item['FW_Version']),
                Lock,
                status
            ]
        })
        utils.generateReport("ohhmed_serialManagement_table", headers, dataToAdd)

    }

    return (
        <div className="w-full px-[3.75rem] py-[2rem]">

            <BodyHeader title={"SN Management"} includeButton={false} includeUpload={true} includePlus={true}
                        buttonText={" Upload File"} readOnly={props.readOnly}
                        OnButtonClick={(file) => uploadSNFile(file)}/>

            <BodySearchFilter
                onSearchChange={(searchText) => onSearchChange(searchText)}
                onDownloadClick={() => onDownloadClick()}
                hasSearch={true}
                hasFilter={false}
                customMargin={"mt-[2.5rem]"}
                hasDownload={true}
            />

            <div className={"w-[10%] ml-auto mt-[1rem] text-[#00000] font-Poppins font-semibold"}>
                Total: {filteredData.length} devices
            </div>

            <div className={"mt-[1.5rem]"}>
                <Table data={filteredData} columns={columns} isLoading={false} rowInEdit={rowInEdit}
                       editCellsArr={editCellsArr}/>
            </div>


        </div>
    )
}

export default SNManagementComp;

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Table} from "../../components/table/table";
import BodySearchFilter from "../../components/customComp/BodySearchFilter";
import BodyHeader from "../../components/customComp/BodyHeader";
import FiltersSideBar from "../../components/sideBar/FiltersSideBar";
import utils from "../../utils/utils";
import {TableRowOptions} from "../../components/table/TableRowOptions";
import constants from "../../utils/constants";


const DeviceManagementComp = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [dataArr, setDataArr] = useState([{}]);

    const [filtersObj, setFiltersObj] = useState({});
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [filterCategoryInputs, setFilterCategoryInputs] = useState([
        {
            label: "General",
            inputs: [
                {name: "Site", label: "Site",placeholder: "Site",type: "Select", data: []},
                {name: "UnlockDate", label: "Unlock date",placeholder: "Unlock date",type: "Date", data: []},
                {name: "ErrorType", label: "Error type",placeholder: "Error type",type: "Select", data: [
                        {value:"All",label:"All"},
                        {value:"CurrentRF1",label:"Current RF1"},
                        {value:"CurrentRF2",label:"Current RF2"},
                        {value:"CurrentRF3",label:"Current RF3"},
                        {value:"VoltageRF1",label:"Voltage RF1"},
                        {value:"VoltageRF2",label:"Voltage RF2"},
                        {value:"VoltageRF3",label:"Voltage RF3"},
                        {value:"ImpedanceRF1",label:"Impedance RF1"},
                        {value:"ImpedanceRF2",label:"Impedance RF2"},
                        {value:"ImpedanceRF3",label:"Impedance RF3"},
                        {value:"TH_1",label:"TH #1"},
                        {value:"TH_2",label:"TH #2"},
                        {value:"TH_3",label:"TH #3"},
                        {value:"TH_4",label:"TH #4"},
                        {value:"TH_5",label:"TH #5"},
                        {value:"TH_6",label:"TH #6"},
                        {value:"System",label:"System"}]
        }]},
        {
            label: "RING",
            inputs:
                [
                    {name: "Ring-NumberOfTreatments", label: "Number of treatments",data:constants.NumberOfTreatmentsFilterData},
                    {name: "Ring-TreatmentTimeAvg", label: "Average treatment time",data:constants.SessionsTimeFilterData},
                    {name: "Ring-QualityFactor", label: "Quality factor %",data:constants.QualityFilterData},
                    {name: "Ring-EnergyLevel", label: "Energy level",data:constants.EnergyLevelFilterData}
                ]
        },
        {
            label: "RING+PAD",
            inputs:
                [
                    {name: "RingPad-NumberOfTreatments", label: "Number of treatments",data:constants.NumberOfTreatmentsFilterData},
                    {name: "RingPad-TreatmentTimeAvg", label: "Average treatment time",data:constants.SessionsTimeFilterData},
                    {name: "RingPad-QualityFactor", label: "Quality factor %",data:constants.QualityFilterData},
                    {name: "RingPad-EnergyLevel", label: "Energy level",data:constants.EnergyLevelFilterData}
                ]
        }
    ]);


    useEffect(() => {
        setIsLoading(true)
        fetchData({}).then(p => {
            let sitesOption = [{value: "All", label: "All"}]
            getAllSites({}).then(sites => {
                sites.data.map(item => {
                    sitesOption.push({value: item['Site_index'], label: item['Site_name']})
                })
            })

            filterCategoryInputs[0].inputs[0].data=sitesOption
            setFilterCategoryInputs(filterCategoryInputs)
            setData(p)
        })

    }, [])

    const getAllSites = async () => {
        return await props.restApi.getAllSites()
    }

    const setData = (p) => {
        let data = [...p.data]
        data.map((entry,index)=>{
            if(entry['deviceSelfTests'].length){
                let errorsArr = getErrorsArr(entry['deviceSelfTests'][0])
                data[index]['errorsText']=errorsArr.join(' ')
            }
            data[index]['Site_name']= data[index].site?.Site_name
        })
        setDataArr([...data])
        setFilteredData([...data])
        setIsLoading(false)
    }

    const fetchData = async (filters) => {
        return await props.restApi.getAllDevicesTrack(filters)
    }


    const columns = [
        {
            Header: 'Site',
            accessor: 'Site_name',
            headerClassName: 'w-[9rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                    >
                    {cell.row.original.Site_name}
                </div>)
            }
        }, {
            Header: 'Unlock Date',
            accessor: 'UnlockDate',
            headerClassName: 'w-[7.125rem]',
            Cell: (cell) => {

                return (<div className={"cursor-pointer leading-5"}
                             onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}>
                    {utils.getFormattedDate(cell.row.original['UnlockDate'])}
                </div>)
            }
        },
        {
            Header: 'External Serial Number',
            accessor: 'External_Serial_Number',
            headerClassName: 'w-[16.375rem]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}>
                    {cell.row.original['External_Serial_Number']}
                </div>)
            }
        },
        {
            Header: 'No. Trnts',
            accessor: 'RingNumberOfTreatments',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#D4EFF1]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {cell.row.original['RingNumberOfTreatments']?cell.row.original['RingNumberOfTreatments']:0}
                </div>)


            }
        },
        {
            Header: 'Avg. Trnt. Time ',
            accessor: 'RingAverageSessionRingTimer',
            additionalText: '(min)',
            headerClassName: 'w-[8rem]',
            backGroundColor:'bg-[#D4EFF1]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.secondsToMMSS(cell.row.original['RingAverageSessionRingTimer'])}
                </div>)


            }
        },
        {
            Header: 'Quality Factor',
            accessor: 'RingAverageQualityFactor',
            additionalText: '(%)',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#D4EFF1]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.getFloatNumber(cell.row.original['RingAverageQualityFactor'])}
                </div>)


            }
        },   {
            Header: 'Energy Level',
            accessor: 'RingavgBatteryVoltage',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#D4EFF1]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.getFloatNumber(cell.row.original['RingavgBatteryVoltage'])}
                </div>)


            }
        },
        {
            Header: 'No. Trnts',
            accessor: 'RingPadNumberOfTreatments',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#E5DDE9]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                             onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {cell.row.original['RingPadNumberOfTreatments']?cell.row.original['RingPadNumberOfTreatments']:0}
                </div>)


            }
        },
        {
            Header: 'Avg. Trnt. Time ',
            accessor: 'RingPadAverageSessionRingTimer',
            additionalText: '(min)',
            headerClassName: 'w-[8rem]',
            backGroundColor:'bg-[#E5DDE9]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.secondsToMMSS(cell.row.original['RingPadAverageSessionRingTimer'])}
                </div>)


            }
        },
        {
            Header: 'Quality Factor',
            accessor: 'RingPadAverageQualityFactor',
            additionalText: '(%)',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#E5DDE9]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.getFloatNumber(cell.row.original['RingPadAverageQualityFactor'])}
                </div>)


            }
        },   {
            Header: 'Energy Level',
            accessor: 'RingPadavgBatteryVoltage',
            headerClassName: 'w-[5.5rem]',
            backGroundColor:'bg-[#E5DDE9]',
            Cell: (cell) => {
                return (<div className={"cursor-pointer"}
                    onClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`)}
                >
                    {utils.getFloatNumber(cell.row.original['RingPadavgBatteryVoltage'])}
                </div>)


            }
        },

        {
            Header: 'Error Type',
            accessor: 'errorsText',
            headerClassName: 'w-[13.625rem]',
            Cell: (cell) => {
                let deviceErrors=[]
                if(cell.row.original['deviceSelfTests'] && cell.row.original['deviceSelfTests'].length>0){
                    let lastError = cell.row.original['deviceSelfTests'][0]
                    deviceErrors = getErrorsArr(lastError)
                }
                return (
                    <TableRowOptions
                    onButtonClick={() => navigate(`/${props.rolePath}/DeviceManagement/${cell.row.original['Device_index']}`,
                        {state: cell.row.original})}
                    options={deviceErrors}
                />)

            }
        }
    ]


    const getErrorsArr=(obj)=>{
        let resultArr=[]
        const errorsSetting={
            CurrentRF1:"Current RF1",
            CurrentRF2:"Current RF2",
            CurrentRF3:"Current RF3",
            VoltageRF1:"Voltage RF1",
            VoltageRF2:"Voltage RF2",
            VoltageRF3:"Voltage RF3",
            ImpedanceRF1:"Impedance RF1",
            ImpedanceRF2:"Impedance RF2",
            ImpedanceRF3:"Impedance RF3",
            TH_1:"TH #1",
            TH_2:"TH #2",
            TH_3:"TH #3",
            TH_4:"TH #4",
            TH_5:"TH #5",
            TH_6:"TH #6",
            System:"System",
        }

        Object.keys(errorsSetting).map(item=>{
            if(obj[item]){
                resultArr.push(errorsSetting[item])
            }
        })
        return resultArr
    }

    const onSearchChange = async (value) => {
        let filterValue = value.toLowerCase()
        console.log({dataArr});
        
        let filteredData = dataArr.filter(user =>
            String(user['External_Serial_Number']).toLowerCase().indexOf(filterValue) > -1 || user?.patient?.name?.toLowerCase()?.includes(filterValue?.toLowerCase()) || user?.patient?.phoneNumber?.includes(filterValue)
        )
        setFilteredData(filteredData)
    }

    const onFilterChange = async (name, value) => {
        let filters = {...filtersObj}

        if(name==='UnlockDate'){
            value = new Date(value)
            value.setHours(12,0,0,0)
        }
        filters[name] = value

        if(name)
        if (name!=="UnlockDate" && (value.indexOf("All")>=0 || value.length===0)) {
            delete filters[name]
        }
        setFiltersObj({...filters})
    }

    const clearFilters = async () => {
        let filters = {}
        setFiltersObj({...filters})
    }
    const onApplyClick = () => {
        fetchData(filtersObj).then(p => {
            setData(p)
            document.body.style.overflow = "auto"
            setIsSideBarOpen(false)
        })
    }

    const openSideBar = () => {
        document.body.style.overflow = "hidden"
        setIsSideBarOpen(true)
    }

    const closeSideBar = () => {
        document.body.style.overflow = "auto"
        setIsSideBarOpen(false)
    }


    const tableSubHeaders = [
        "", "", "",
        {
            rows: 4,
            text: "Ring",
            color: "#D4EFF1",
            className: `h-[2.313rem] bg-[#D4EFF1] border-b border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
        {
            rows: 4,
            text: "RING+PAD",
            color: "#E5DDE9",
            className: `h-[2.313rem] bg-[#E5DDE9] border-b border-[#E0E0D7] text-center rounded-t-lg text-black font-Poppins text-lg font-bold leading-5`
        },
    ]


    const onDownloadClick = () => {
        let headers = ["Site","Unlock Date", "External S/N",
            "No. Trnts RING", "Avg. Trnt. Time (min) RING", "Quality Factor RING", "Energy level RING",
            "No. Trnts RING+PAD", "Avg. Trnt. Time (min) RING+PAD", "Quality Factor RING+PAD", "Energy level RING+PAD"
            ,"Error Type","Base line IIEF-EF","Actual IIEF-EF"]

        let dataToAdd = filteredData.map(item => {
            let errorsArr = item['deviceSelfTests'] && item['deviceSelfTests'].length > 0 ?
                getErrorsArr(item['deviceSelfTests'][0]) : []
            let patient = item['patient']?item['patient']:{}
            return [
                item['site']['Site_name'],
                utils.getFormattedDate((item['UnlockDate'])),
                Number(item['External_Serial_Number']),
                 Number(item['RingNumberOfTreatments']),
                 Number(item['RingAverageSessionRingTimer']),
                 Number(item['RingAverageQualityFactor']),
                 Number(item['RingavgBatteryVoltage']),
                 Number(item['RingPadNumberOfTreatments']),
                 Number(item['RingPadAverageSessionRingTimer']),
                 Number(item['RingPadAverageQualityFactor']),
                 Number(item['RingPadavgBatteryVoltage']),
                errorsArr.join(","),
                patient['BaseLineIIEF'],
                patient['ActualLineIIEF']
            ]
        })

        utils.generateReport("ohhmed_devices_management_table", headers, dataToAdd)
    }


    const onAdditionalDownloadClick = async () => {
        let devicesIndexes = filteredData.map(item=>{
            return item['Device_index']
        })
        let response = await props.restApi.exportAllDevicesDashboardData(devicesIndexes)
        let data= response && response.data?response.data:[]
        utils.downloadAllDevicesDashboardData(data)

    }

    return (
        <div className="w-full px-[3.75rem] py-[3rem]">

            <BodyHeader title={"Device Management"} includeButton={false} includeTotal={true}
                        totalText={"Total: "+filteredData.length+" devices"}/>

            <BodySearchFilter
                onFilterChange={(name, value) => onFilterChange(name, value)}
                filtersObj={filtersObj}
                onSearchChange={(searchText) => onSearchChange(searchText)}
                hasSearch={true}
                onDownloadClick={() => onDownloadClick()}
                hasNewLine={false}
                hasFilter={false}
                hasAdvanceFilter={true}
                onAdvanceFilterClick={openSideBar}
                hasDownload={true}
                hasAdditionalDownload={true}
                onAdditionalDownloadClick={() => onAdditionalDownloadClick()}
                additionalDownloadText={"Download Devices Dashboard"}
                downloadText={"Download Devices Management"}
                filterInputs={[]}
            />


            <div className={"mt-[1.5rem]"}>
                <Table data={filteredData} columns={columns} isLoading={isLoading} subHeaders={tableSubHeaders}/>
            </div>


            {isSideBarOpen && <FiltersSideBar
                restApi={props.restApi}
                filtersObj={filtersObj}
                onApplyClick={(name, value) => onApplyClick(name, value)}
                onFilterChange={(name, value) => onFilterChange(name, value)}
                sideBarWidth={'w-[31.5rem]'}
                gridStyle={' grid grid-cols-1 gap-y-[1.5rem]'}
                filtersCategory={filterCategoryInputs}
                setIsLoading={() => setIsLoading(!isLoading)}
                closeSideBar={() => closeSideBar()}
                clearFilters={() => clearFilters()}
                isSideBarOpen={isSideBarOpen}
            />}
        </div>
    )
}

export default DeviceManagementComp;
